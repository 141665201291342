@use '../../../styles/styles.scss' as *;

.soundSwitch {
  border-radius: 50%;
  font-size: 22px;
  @include flexCenter;
  @include width-height(52px);
  @include themed() {
    background-color: t($buttonBgActive);
    color: t($buttonLabelActive);
  }

  &::before {
    @include textCenter;
    padding: 0 0 0 2px;
  }

  &:hover {
    transition: 0.3s;
    @include themed() {
      background-color: t($buttonBgHover3);
    }
  }

  &_off {
    &::before {
      padding: 0;
    }

    @include themed() {
      background-color: t($buttonBgActive);
      color: t($buttonLabelActive);
    }

    &:hover {
      @include themed() {
        background-color: t($buttonBgHover3);
      }
    }
  }

  &:disabled,
  &:disabled:hover {
    @include themed() {
      color: t($buttonLabelDisabled);
    }
  }
}

@media (max-width: $sizeWidth1024) {
  .soundSwitch {
    font-size: 16px;
    @include width-height(40px);
  }
}

@media (max-width: $sizeWidth768) {
  .soundSwitch {
    font-size: 12px;
    @include width-height(28px);
  }
}
