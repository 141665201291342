@use '../../../../styles/styles.scss' as *;

.modal {
  position: fixed;
  left: 0;
  top: 0;
  @include width-height(100%);
  @include flexCenter;
  z-index: 1600;
  background-color: $darkenedBackground;

  &__container {
    position: relative;
    width: 100%;
    @include themed() {
      border-radius: t($radiusSmall);
      color: t($textPrimaryMain);
      background-color: t($bg);
      box-shadow: t($shadowBgPopup);
    }
  }

  &__wrapper {
    @include themed() {
      border-radius: t($radiusSmall);
      color: t($textPrimaryMain);
      background-color: t($bg);
      box-shadow: t($shadowBgPopup);
    }
  }

  &__header {
    position: relative;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 156;
    font-size: 24px;
    background-color: transparent;
    @include themed() {
      color: t($buttonLabelDefault1);
    }
    transition: color 0.3s ease;

    &:hover {
      @include themed() {
        color: t($buttonLabelHover2);
      }
    }
  }

  &Transaction {
    width: 1016px;
    height: 452px;
    padding: 36px 40px;
    overflow-y: hidden;

    &__container {
      display: flex;
      @include width-height(100%);
      gap: 40px;
    }
  }
}

@media all and (max-width: $sizeWidth1280) {
  .modal {
    &Transaction {
      max-width: 700px;
      padding: 36px 40px 20px 20px;
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .modal {
    &__close {
      font-size: 16px;
    }

    &__container {
      border-radius: 0 !important;
    }

    &__wrapper {
      border-radius: 0;
    }

    &Transaction {
      max-width: none;
      @include width-height(100%, 100vh);

      &__imgBlock {
        @include flexCenter;
        height: fit-content;
        width: 100%;
        min-width: 240px;
        flex: 0.5 1 auto;
        position: relative;

        &__imgBlock_img {
          top: 0;
          position: relative;
        }
      }

      &__info {
        gap: $space8;
        align-items: center;
      }

      &__title {
        width: 100%;
        @include fontStyle(32px, 400, 40px);
      }

      &__text {
        width: 100%;
        flex: 0 1 auto;
      }

      &__btn {
        align-self: stretch;
        min-height: $space14;
      }
    }
  }
}
