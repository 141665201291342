@use '../../styles/styles.scss' as *;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &_sticky {
    height: 100%;
  }

  &__layout {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: url('../../assets/images/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    @include themed() {
      background-color: t($bg);
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    transform-origin: top center;
    width: 100%;

    max-width: $sizeContainer;
  }

  &__navbar {
    padding-left: 200px;
  }

  &__authorization {
    position: absolute;
    margin: auto;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    @include themed() {
      border-bottom: 1px solid t($inputBgGuides);
    }
  }

  &__header_sticky {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  &__header_shadow {
    background: rgba(23, 23, 23, 0.04);

    &::before {
      content: '';
      position: absolute;

      @include width-height(100%);
      backdrop-filter: blur(20px);
      z-index: -1;
    }
  }
}

@media all and (max-height: $sizeHeight1400) {
  .wrapper {
    @include width-height(100%);

    &_sticky {
      height: 100%;
    }

    &__container {
      height: 100%;
    }

    &__content {
      display: grid;
      height: 100%;
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }
}

@media all and (max-width: $sizeWidth1024) {
  .wrapper {
    &__chatHeader {
      display: none;
    }

    &__navbar {
      padding-left: 0
    }

    &__content {
      padding-bottom: 68px
    }
  }
}

@media all and (max-height: $sizeHeight1200) {
  .wrapper {
    width: 100%;

    &_sticky {
      height: 100%;
    }

    &__container {
      height: 100%;
    }

    &__content {
      display: grid;
      height: 100%;
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }
}

@media (max-height: $sizeHeight800) {
  .wrapper {
    width: 100%;

    &_sticky {
      height: 100%;
    }

    &__container {
      height: 100%;
    }

    &__content {
      align-items: stretch;
    }
  }
}
