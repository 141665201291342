@use '../../../../styles/styles.scss' as *;

.socialButton {
  background-color: $colorGray650;
  width: 100%;
  user-select: none;
  cursor: pointer;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: $gradientGray;
  }

  &__logo {
    height: 40px;
  }

  &__name {
    @include fontRussoOne;
  }
}

.referral {
  @include flex-grid(flex, row, center, center);
  padding: 8px 24px;
  gap: 8px;
  border-radius: 12px;
  @include fontStyle(16px, 400, 24px);
}

.auth {
  @include flex-grid(flex, row, flex-start, center);
  border-radius: 16px;
  padding: 12px 30px;
  @include fontStyle(20px, 400, 28px);
  gap: 16px;
  height: 68px;

  img {
    width: 32px;
  }
}

.wallet {
  @include flex-grid(flex, row, flex-start, center);
  border-radius: 16px;
  padding: 12px 30px;
  @include fontStyle(20px, 400, 28px);
  gap: 16px;
  height: 68px;

  img {
    width: 32px;
  }
}

@media all and (max-width: $sizeWidth1024) {
  .auth {
    @include flex-grid(flex, row, center, center);

    p {
      display: none;
    }

    img {
      width: 40px;
    }
  }

  .wallet {
    img {
      width: 40px;
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .referral {
    gap: 4px;
    padding: 8px 12px;
    @include fontStyle(14px, 400, 20px);

    img {
      height: 24px;
    }
  }

  .auth {
    @include flex-grid(flex, row, flex-start, center);

    p {
      display: flex;
    }
  }
}

@media all and (max-width: $sizeWidth480) {
  .auth {
    gap: 12px;
    padding: 12px;

    p {
      @include fontStyle(16px, 400, 24px);
    }
  }

  .wallet {
    gap: 12px;
    padding: 12px;

    p {
      @include fontStyle(16px, 400, 24px);
    }
  }
}
