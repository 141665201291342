@use '../../../../styles/styles' as *;

.menu {
  position: relative;
  z-index: 150;

  &__img {
    cursor: pointer;
    @include width-height(70px, 56px);
    margin-top: 4px;

    & img {
      @include width-height(100%);
      object-fit: cover;
    }
  }

  &__logout {
    @include width-height(100%, 44px);
    @include themed() {
      border-top: 1px solid t($surfaceBorderSubtitles);
    }
    @include flexCenter;
    justify-content: flex-end;

    &__button {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: center;
      background-color: transparent;
      @include themed() {
        @include fontStyle(20px, 400, 26px);
        color: t($buttonLabelDefault1);
      }

      &:hover {
        @include themed() {
          color: t($messageLabelGuides);
        }
      }
    }
  }

  &__list {
    position: absolute;
    min-width: 284px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 12px 12px 20px 20px;
    z-index: 50;
    padding: $space5;
    margin-top: 14px;
    @include themed() {
      border: 1px solid t($surfaceBorderSubtitles);
      background: t($surfacePrimary2);
    }

    &::before {
      align-self: flex-end;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -14px;
      display: block;
      @include width-height($space18, 16px);
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.58547 1.84438C9.17557 1.23113 10.1571 1.23113 10.7472 1.84438L17.5942 8.95994C18.5111 9.91289 17.8358 11.5 16.5133 11.5H2.8194C1.4969 11.5 0.821546 9.91289 1.73853 8.95994L8.58547 1.84438Z' fill='%23222222' stroke='%23636363'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__sublist {
    position: absolute;
    width: 100%;
    bottom: 58px;
    display: none;
    flex-direction: column;
    @include themed() {
      background: t($surfacePrimary2);
    }
  }

  &__item {
    background-color: transparent;
    width: 100%;

    &_main {
      @include flex-grid(flex, row, none, none);
      gap: 12px;
    }
  }

  &__submenu {
    width: 100%;
    position: relative;
    @include flex-grid(flex, column, none, none);

    .menu__sublist {
      display: none;

      &_opened {
        display: flex;
      }
    }

    .item .item__title {
      @include flexCenter;
    }

    &_opened {
      .item__title {
        @include flexCenter;

        &::after {
          rotate: 180deg;
        }
      }
    }
  }
}

.item {
  @include flex-grid(flex, none, none, center);
  align-self: stretch;
  @include width-height(100%, 58px);
  padding: $space4;
  gap: 12px;
  cursor: pointer;
  @include fontStyle(20px, 500, 24px);
  @include themed() {
    color: t($textPrimaryMain);
  }

  &__row {
    justify-content: space-between;
    width: 100%;
  }

  &:hover {
    @include themed() {
      background: t($gradientButtonHover1);
    }
    border-radius: $space4;
  }

  &_subitem {
    width: 100%;
    @include fontStyle(18px, 500, 22px);
  }

  &__title {
    display: flex;
    gap: 12px;
    white-space: nowrap;

    &::after {
      @include flexCenter;
      font-size: 6px;
      rotate: 270deg;
    }
  }

  &__icon {
    @include flexCenter;
    @include width-height(24px);

    & img {
      @include width-height(100%);
    }

    &_flag {
      @include width-height(24px);
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .menu {
    &__list {
      min-width: 250px;
    }
  }
  .item {
    @include fontStyle(16px, 700, 20px);
    height: 48px;

    &_subitem {
      @include fontStyle(16px, 700, 20px);
    }
  }
}

@media all and (max-width: $sizeWidth480) {
  .menu {
    &__img {
      @include width-height(52px, 44px);
      margin-top: 2px;
    }

    &__list {
      &::before {
        @include width-height($space13, 16px);
      }
    }
  }
}
