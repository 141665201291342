@use '../../../styles/styles.scss' as *;

.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-color: $colorBlack;

  @include flexCenter;

  &::after {
    content: ""; // Необходимо для отображения псевдоэлемента
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../../assets/images/questBgLights.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1; // Убедитесь, что z-index позволяет псевдоэлементу оставаться за основным содержимым
  }

}

.info {
  @include flexColumnCenter;
  gap: 40px;

  &__text {
    @include fontRussoOne;
    color: $colorWhite;
    @include fontStyle(36px, 400, 44px);
  }

  &__title {
    @include fontStyle(48px, 400, 56px);
  }

  &__button {
    padding: 16px 32px;
    background-color: $colorGreenPrimary200;
    @include fontRoboto;
    @include fontStyle(20px, 700, 24px);
  }
}

@media all and (max-width: $sizeWidth1280) {
  .info {
    gap: 30px;

    &__text {
      @include fontStyle(16px, 400, 24px);
    }

    &__title {
      @include fontStyle(24px, 400, 28px);
    }

    &__button {
      margin-top: 20px;
    }

    &__img {
      height: 525px;
    }
  }
}

@media all and (max-width: $sizeWidth480) {
  .container {
    flex-direction: column;
  }
  .info {
    gap: 20px;
    width: 100%;
    padding: 20px;
    order: 2;

    &__text {
      color: $colorWhite;
      @include fontStyle(16px, 400, 24px);
    }

    &__title {
      @include fontStyle(24px, 400, 28px);
    }

    &__button {
      margin-top: 30px;
      width: 100%;
    }

    &__img {
      height: 380px;
    }
  }
}


