@use '../../../styles/styles.scss' as *;

.actions {
  @include width-height(100%, fit-content);
  @include flexCenter;
  justify-content: flex-end;
  gap: 20px;
}

@media all and (max-width: $sizeWidth767) {
  .actions {
    justify-content: center;
    flex-direction: column;
  }
}
