@use '../../../styles/styles.scss' as *;

.content {
  @include flexColumnCenter;
  gap: 40px;

  &__text {
    @include fontRussoOne;
    @include themed {
      color: t($textPrimaryMain);
    }
    @include fontStyle(24px, 400, 28px);
  }

  &__title {
    @include fontStyle(28px, 400, 32px);
  }

  &__rewardBox {
    min-height: 92px;
    min-width: 540px;
    @include width-height(540px, fit-content);

    &__panel {
      position: relative;
      @include width-height(100%);
      @include flexCenter;
      flex-direction: column;
      min-height: 92px;
      box-shadow: 2px 4px 49.2px 0 rgba(255, 165, 0, 0.35), 1px 1px 2.5px 0 rgba(0, 0, 0, 0.50);
    }

    &__item {
      @include flexCenter;
      justify-content: flex-start;
      gap: 8px;
      position: relative;
      margin-top: -30px;

      &:first-child {
        margin-top: 0;
      }
    }

    &__text {
      @include fontRussoOne;
      @include fontStyle(20px, 400, 24px);
      @include themed {
        color: t($textPrimaryMain);
      }

      &__gold {
        @include themed {
          color: t($buttonBgTertiary);
        }
      }
    }

    &__icon {
      @include width-height(60px);
    }

    &__container {
      height: fit-content;
    }

    &__stars {
      position: absolute;
      top: 4px;
      right: 0;
    }
  }

  &__button {
    align-self: flex-end;
    margin: 20px 20px 0 0;
  }

  &__mascot {
    display: none;
  }
}

.mascot {
  @include width-height(372px, 414px);
}

@media all and (max-width: $sizeWidth1024) {
  .mascot {
    display: none;
  }
  .content {
    &__rewardBox {
      margin: 0 80px;
      @include width-height(100%, fit-content);
    }

    &__button {

      margin-bottom: 32px;
      margin-right: 0;
    }
  }
}

@media all and (max-width: $sizeWidth767) {
  .content {
    &__rewardBox {
      min-width: 0;

      &__text {
        @include fontRussoOne;
        @include fontStyle(18px, 400, 22px);
      }
    }

    &__button {
      align-self: center;
      margin-bottom: 0;
      @include width-height(100%)
    }

    &__mascot {
      height: 100%;
      max-height: 500px;
      display: block;
    }
  }
}

@media all and (max-width: $sizeWidth480) {
  .content {
    gap: 24px;

    &__rewardBox {
      width: 100%;
    }

    &__mascot {
      width: 100%;
    }
  }
}
