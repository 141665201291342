$iconsFontFamily: 'iconsFont' !default;
$iconsFontPath: '../../assets/fonts' !default;

$iconSoundOff: '\e900';
$iconSoundOn: '\e901';
$iconTwitter: '\e902';
$iconLoader: '\e903';
$iconSend: '\e904';
$iconEyeClose: '\e905';
$iconEyeOpen: '\e906';
$iconArrowBack: '\e907';
$iconEmail: '\e908';
$iconArrowMessage: '\e909';
$iconClose: '\e90a';
$iconError: '\e90b';
$iconPlay: '\e90c';
$iconPause: '\e90d';
$iconRewind: '\e90e';
$iconSearch: '\e90f';
$iconArrowSend: '\e910';
$iconPazzle: '\e911';
$iconUser: '\e912';
$iconUsers: '\e913';
$iconCopy: '\e914';
$iconLink: '\e915';
$iconEdit: '\e916';
$iconDropdownArrow: '\e917';
$iconDisconnect: '\e918';
$iconLogout: '\e919';
$iconSeparator: '\e91a';
$iconFacebook: '\e91b';
$iconLinkedin: '\e91c';
$iconDiscord: '\e91d';
$iconTelegram: '\e91e';
$iconDone: '\e91f';
$iconRadioUnchecked: '\e920';
$iconRadioChecked: '\e921';
