@use '../../../styles/styles.scss' as *;

.guideInfo {
  scrollbar-width: none;
  position: relative;
  z-index: 150;
  max-width: 1016px;
  height: fit-content;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: -110px;
    right: -450px;
    width: 100%;
    height: 100%;
    transform: rotate(-30deg);
    background-image: linear-gradient(180deg,
            rgba(167, 49, 255, 0) 0%,
            rgba(167, 49, 255, 0.15) 50%,
            rgba(86, 89, 254, 0.15) 90%,
            rgba(167, 49, 255, 0) 100%);
    backdrop-filter: blur(5px);
  }

  &__container {
    z-index: 155;
    position: relative;
    @include flexCenter;
    gap: 20px;
    padding: 40px 20px 0 20px;
    @include width-height(100%);
  }

  &__imgContainer {
    @include flexCenter;
    align-items: self-end;
    height: 100%;
  }

  &__btnClose {
    padding: 20px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  @include width-height(100%);
  gap: 8px;
  padding-bottom: 20px;

  &__title {
    @include textCenter;
    @include fontRussoOne;
    @include fontStyle(28px, 400, 32px);
    padding-bottom: 16px;
  }

  &__subtitle {
    @include fontStyle(20px, 700, 24px);
    margin: 32px 0 16px;
  }

  &__text {
    @include fontStyle(20px, 700, 24px);
  }

  &__grey {
    @include themed() {
      color: t($buttonLabelActive3);
    }
  ;
    padding-right: 8px;
  }

  &__link {
    @include themed() {
      color: t($textAccent);
    }
  ;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__loading {
    align-self: center;
  }

  &__loader {
    @include width-height(50px);
    align-self: center;

    justify-self: center;
    font-size: 50px;
    animation: spin 5s linear 0s infinite;
  }
}

@media all and (max-width: $sizeWidth1024) {
  .guideInfo {
    padding-top: 4px;
    max-width: 700px;

    &__img {
      width: 240px;
    }

    &__container {
      align-items: flex-end;
    }

    &::after {
      top: -60px;
      right: -330px;
      transform: rotate(-130deg);
    }

    &__btnClose {
      padding: 10px 20px;

      &::before {
        font-size: 16px;
      }
    }
  }
  .info {
    &__title {
      padding-bottom: 12px;
    }

    &__text {
      @include fontStyle(16px, 700, 24px);
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .guideInfo {
    max-width: none;
    height: 100%;
    padding: 20px 20px 0 20px;

    &__container {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    &__imgContainer {
      align-items: self-end;
      order: 2;
    }

    &__img {
      width: 300px;
    }

    &::after {
      top: 500px;
      right: -300px;
      transform: rotate(-40deg);
    }

    &__btnClose {
      padding: 0;
    }
  }
  .info {
    padding-top: 30px;

    &__title {
      @include fontStyle(28px, 400, 32px);
      padding-bottom: 20px;
    }

    &__text {
      @include fontStyle(16px, 700, 24px);
    }

    &__subtitle {
      margin: 20px 0 0;
    }

    &__buttons {
      margin: 20px 0;
      flex-direction: column;
      gap: 20px;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
