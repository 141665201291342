@use '../../../styles/styles.scss' as *;

.content {
  @include flexColumnCenter;
  justify-content: flex-start;
  gap: 40px;

  &__sticker {
    @include textCenter;
    @include fontStyle(20px, 400, 24px);
  }

  &__infoBox {
    min-height: 92px;
    min-width: 510px;
    @include width-height(510px, 92px);

    &__panel {
      @include width-height(100%);
      @include flexColumnCenter;
      min-height: 92px;
    }

    &__text {
      @include themed {
        color: t($textAccent);
      }
    }
  }

  &__actions {
    margin: 20px 20px 0 0;
  }

  &__mascot {
    display: none;
  }

  &__text {
    @include textCenter;
  }
}

.mascot {
  @include width-height(382px, 363px);
  @include flexCenter;
}

@media all and (max-width: $sizeWidth1024) {
  .mascot {
    display: none;
  }
  .content {
    &__infoBox {
      margin: 0 80px;
      max-width: 510px;
      @include width-height(100%, 92px);
    }

    &__actions {
      margin-bottom: 32px;
      margin-right: 0;
    }
  }
}

@media all and (max-width: $sizeWidth767) {
  .content {
    &__infoBox {
      min-width: 0;
    }

    &__actions {
      margin-bottom: 0;
    }

    &__button {
      @include width-height(100%)
    }

    &__mascot {
      @include flexCenter;
      height: 500px;
      max-height: 800px;
    }
  }
}

@media all and (max-width: $sizeWidth480) {
  .content {
    gap: 24px;

    &__infoBox {
      width: 100%;
    }

    &__mascot {
      width: 100%;
    }
  }
}
