@use '../styles/styles.scss' as *;
@import '../styles/reset.scss';
@import '../styles/fonts/fonts.scss';
@import '../styles/fonts/iconsFont';

* {
  font-family: 'Roboto', Arial, Helvetica, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  .grecaptcha-badge {
    z-index: 1000 !important;
    visibility: hidden;
  }
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
}

@media (max-width: 600px) {
  .grecaptcha-badge {
    transform: scale(0.65);
    right: -168px !important;
    bottom: 60px !important;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .grecaptcha-badge {
    transform: scale(0.8);
    right: -175px !important;
    bottom: 65px !important;
  }
}

@media (min-width: 1025px) {
  .grecaptcha-badge {
    transform: scale(0.9);
    right: -185px !important;
    bottom: 100px !important;
  }
}
