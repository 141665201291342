@use '../../../styles/styles.scss' as *;

.panel {
  @include width-height(fit-content);
  padding: 12px;
  @include flexCenter;
  @include themed {
    background: red;
    border: 1px solid t($buttonBgTertiary);
    border-radius: 16px;
  }

  &.bgSecondary {
    @include themed {
      background: linear-gradient(137deg, rgba(23, 23, 23, 0.50) 11.14%, rgba(50, 48, 48, 0.50) 84.99%);
    }
  }

  &.bgBlack {
    @include themed {
      background: $colorBlack;
    }
  }

  &.borderSecondary {
    @include themed {
      border-color: t($surfacePrimary);
    }
  }

  &.borderGreen {
    @include themed {
      border-color: t($textAccent);
    }
  }

  &.borderRed {
    @include themed {
      border-color: t($buttonPlaceholderError);
    }
  }
}
