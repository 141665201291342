@use '../../../../styles/styles.scss' as *;

.block {
  position: relative;
  @include themed() {
    background-color: t($surfacePrimary);
    @include borderStyle(1px solid t($surfaceBorderSubtitles), 28px);
  }
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    @include width-height(100%);
    backdrop-filter: blur(15px);
    border-radius: 28px;
  }
}
