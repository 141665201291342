@use '../../../styles/styles.scss' as *;
@use '../../../components/Auth/style.module.scss' as *;

.connectWindow {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    justify-content: center;
  }

  .connectWindow__spinner2 {
    display: none;
  }
}

.header {
  &__close {
    background-color: transparent;
    position: absolute;
    right: 0;
    font-size: 24px;
    padding: 10px 0 10px 10px;
  }
}

.mascot {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__body,
  &__hands {
    @include width-height(208px, 280px);
    margin-top: 54px;
  }

  &__body {
    position: absolute;
    z-index: 0;
  }

  &__hands {
    z-index: 3;
  }

  &__text {
    position: relative;
    @include textStyleButton2;
    grid-template-columns: 1fr fit-content(20px);
    grid-template-rows: 1fr 64px;
    gap: 20px;
    margin-top: -48px;
    padding: 20px;
    z-index: 2;

    &_noAnswer {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  }
}

.statusText {
  @include fontRussoOne;
  @include fontStyle(40px, 400, 44px);
}

@media all and (max-width: $sizeWidth1024) {
  .statusText {
    @include fontRussoOne;
    @include fontStyle(32px, 400, 40px);
  }
}

@media all and (max-width: $sizeWidth768) {
  .connectWindow {
    height: 100%;
    justify-content: space-between;

    .connectWindow__spinner {
      display: none;
    }

    .connectWindow__spinner2 {
      margin-top: 5%;
      display: flex;
    }
  }
  .mascot {
    justify-content: space-between;
    max-height: 550px;
    height: 100%;

    &__body,
    &__hands {
      display: none;
    }

    .mascot__text {
      @include fontStyle(20px, 500, 24px);
      grid-template-columns: 1fr;
      grid-template-rows: 1fr $space14;
      grid-template-areas:
        "text text"
        "button button";
      gap: 20px;

      &_noAnswer {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 0;
      }
    }
  }
}

@media all and (max-width: $sizeWidth480) {
  .statusText {
    width: calc(100% - 26px);
    @include fontStyle(24px, 400, 28px);
    text-align: center;
  }
  .mascot {
    justify-content: space-between;
    max-height: 550px;
    height: 100%;

    &__body,
    &__hands {
      display: none;
    }

    .mascot__text {
      @include fontStyle(20px, 500, 24px);
      grid-template-columns: 1fr;
      grid-template-rows: 1fr $space14;
      grid-template-areas:
        "text text"
        "button button";
      gap: 20px;

      &_noAnswer {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 0;
      }
    }
  }
}
