@use '../../../styles/styles.scss' as *;
@use '../../ui/buttons/Button/style.module.scss' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 40px;
  @include width-height(100%, fit-content);

  &__logo {
    display: none;

    &__shown {
      display: block;
    }
  }

  &__withLogo {
    justify-content: space-between;
  }
}

.option {
  gap: 12px;
  @include flexCenter;

  &__menu {
    height: 56px;
  }

  &__login {
    @include fontStyle(16px, 700, 24px);
    padding: 0 24px;
    height: 40px;
    @include flexCenter;
    @include themed() {
      border-width: 2px;
    }
  }
}

@media all and (max-width: $sizeWidth1024) {
  .header {
    justify-content: space-between;
    padding: 12px 24px;

    &__logo {
      @include fontStyle(14px, 700, 32px);
      @include width-height(100%, 48px);
      display: block;
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .option {
    &__login {
      @include fontStyle(14px, 700, 32px);
      width: fit-content;
      align-self: end;
    }
  }
}

@media all and (max-width: $sizeWidth767) {
  .header {
    padding: 8px 20px;

    &__logo {
      @include width-height(100%, 44px);
    }
  }
}

@media all and (max-width: $sizeWidth480) {
  .option {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &__menu {
      justify-self: end;
      align-self: center;
      height: 44px;
    }

    &__points {
      margin-top: 4px;
    }
  }
}

@media all and (max-width: $sizeWidth360) {
  .header {
    padding: 8px 16px;
  }
}
