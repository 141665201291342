@use '../../styles/styles.scss' as *;

.content {
  width: 100%;
  position: relative;

  &_object {
    width: 100%;
    position: fixed;
    height: 100vh;
  }
}
