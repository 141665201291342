@use '../../../styles/styles' as *;

.modal {
  max-width: 576px;
  padding: 40px;
  border-radius: 16px;
}

.title {
  @include fontStyle(24px, 700);
}

.link {
  @include width-height(100%, 60px);
  @include flex-grid(flex, null, space-between, center);
  gap: 16px;
  padding: 14px 36px;
  margin: 24px 0;
  @include themed() {
    border: 1px solid t($surfaceBorder1);
    border-radius: t($radiusSmall);
    background: t($formBackground);
  }

  &_done {
    @include themed() {
      border: 1px solid t($surfaceBorderAccent);
    }
  }

  &__text {
    @include fontStyle(20px, 400, 24px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__copy {
    cursor: pointer;
    background-color: transparent;
    font-size: 26px;
    @include width-height(32px);
    @include flexCenter;
    @include themed() {
      color: t($buttonBorderHover);
    }

    &:disabled {
      cursor: initial;

      &::after {
        padding-top: 5px;
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTcuNkwxMS43MTQzIDI0TDI2IDgiIHN0cm9rZT0iI0E3RjY3QiIgc3Ryb2tlLXdpZHRoPSIyLjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
      }
    }

    &:hover {
      transition: 0.3s;
      @include themed() {
        color: t($messageLabelGuides);
      }
    }
  }
}

// .socials {
//   @include flex-grid(flex, null, space-between, center);

//   &__link {
//     border-radius: 50%;
//     font-size: 28px;
//     @include width-height(56px);
//     @include flexCenter;
//     @include themed() {
//       color: t($bgSecondary);
//       background-color: t($surfacePrimary2);
//     }
//   }
// }

.socials {
  @include flex-grid(flex, row, center, center);
  gap: 20px;
}

@media all and (max-width: $sizeWidth768) {
  .modal {
    max-width: 90%;
    border-radius: 16px !important;
  }
  .title {
    @include fontStyle(20px, 500);
    max-width: 85%;
  }
  .link {
    padding: 8px 14px;
    height: 52px;

    &__text {
      @include fontStyle(14px, 700, 16px);
    }

    &__copy {
      @include width-height($space7);
    }
  }
  // .socials {
  //   &__link {
  //     @include width-height(36px);
  //   }
  // }
}