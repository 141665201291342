@use '../../../styles/styles.scss' as *;

.popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1600;
  background-color: $darkenedBackground;
  @include width-height(100%);
  @include flexCenter;
  @include themed {
    color: t($textPrimaryMain);
  }

  .container {
    padding: 20px 24px;
    position: relative;
    @include width-height(fit-content);

    @include themed() {
      border-radius: t($radiusSmall);
      border: 1px solid t($surfacePrimary);
      color: t($textPrimaryMain);
      background-color: t($bg);
      box-shadow: t($shadowBgPopup);
    }

    .header {
      @include flexCenter;
      @include width-height(100%, 24px);
      justify-content: flex-end;

      .close {
        font-size: 24px;
        background-color: transparent;
        @include themed() {
          color: t($buttonLabelDefault1);
        }
        transition: color 0.3s ease;

        &:hover {
          @include themed() {
            color: t($buttonLabelHover2);
          }
        }
      }
    }

    .body {
      display: flex;
      gap: 24px;
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .popup {
    .container {
      .header {
        @include width-height(100%, 20px);

        .close {
          font-size: 20px;
        }
      }
    }
  }
}

@media all and (max-width: $sizeWidth767) {
  .popup {
    .container {
      width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .header {
        @include width-height(100%, 16px);

        .close {
          font-size: 16px;
        }
      }

      .body {
        //@include width-height(100%);
        flex-direction: column-reverse;

      }
    }
  }
}

@media all and (max-width: $sizeWidth480) {
  .container {
    border: none !important;
    box-shadow: none !important;
  }
}
