@use '../../styles/styles.scss' as *;

.baseSubtitle {
  display: grid;
  width: 100%;
  grid-template-columns: fit-content(50px) 1fr fit-content(50px);
  grid-template-areas:
    'audio text .'
    'audio .    button';

  &_noAudio {
    grid-template-columns: 1fr fit-content(50px);
    grid-template-areas:
      'text .'
      '.    button';
  }

  &__mascotVoice {
    grid-area: audio;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__mascotText {
    grid-area: text;
    width: 100%;
  }

  @media all and (max-width: $sizeWidth768) {
    grid-template-columns: fit-content(30px) 1fr fit-content(50px);
    grid-template-areas:
      'audio text text'
      'audio .    button';
  }
}

.answer {
  display: flex;
  grid-area: button;
  width: 100%;
  gap: 20px;

  &__button {
    @include fontStyle(20px, 700);
    min-width: 160px;
    height: 100%;
    width: fit-content;
    padding: 0 32px;
    @media all and (max-width: $sizeWidth768) {
      width: 100%;
    }

    &_size {
      &_l {
        @include fontStyle(24px, 700);
        padding: 0 32px;
        @media all and (max-width: $sizeWidth1024) {
          @include fontStyle(16px, 700);
          min-width: 100px;
          padding: 0 14px;
        }
      }
    }
  }
}
