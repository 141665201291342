@import 'iconsFontVariables';

@font-face {
  font-family: '#{$iconsFontFamily}';
  src: url('#{$iconsFontPath}/#{$iconsFontFamily}.eot?hdyx3n');
  src: url('#{$iconsFontPath}/#{$iconsFontFamily}.eot?hdyx3n#iefix') format('embedded-opentype'),
  url('#{$iconsFontPath}/#{$iconsFontFamily}.woff?hdyx3n') format('woff'),
  url('#{$iconsFontPath}/#{$iconsFontFamily}.ttf?hdyx3n') format('truetype'),
  url('#{$iconsFontPath}/#{$iconsFontFamily}.svg?hdyx3n##{$iconsFontFamily}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon']::before,
[class*=' icon']::before,
[class^='icon']::after,
[class*=' icon']::after,
input[type='password'] {
  font-family: '#{$iconsFontFamily}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconSoundOff {
  &:before {
    content: $iconSoundOff;
  }
}

.iconSoundOn {
  &:before {
    content: $iconSoundOn;
  }
}

.iconTwitter {
  &:before {
    content: $iconTwitter;
  }
}

.iconLoader {
  &:before {
    content: $iconLoader;
  }
}

.iconSend {
  &:before {
    content: $iconSend;
  }
}

.iconEyeClose {
  &:before {
    content: $iconEyeClose;
  }
}

.iconEyeOpen {
  &:before {
    content: $iconEyeOpen;
  }
}

.iconArrowBack {
  &:before {
    content: $iconArrowBack;
  }
}

.iconEmail {
  &:before {
    content: $iconEmail;
  }
}

.iconArrowMessage {
  &:before {
    content: $iconArrowMessage;
  }
}

.iconClose {
  &:before {
    content: $iconClose;
  }
}

.iconError {
  &:before {
    content: $iconError;
  }
}

.iconPlay {
  &:before {
    content: $iconPlay;
  }
}

.iconPause {
  &:before {
    content: $iconPause;
  }
}

.iconRewind {
  &:before {
    content: $iconRewind;
  }
}

.iconSearch {
  &:before {
    content: $iconSearch;
  }
}

.iconArrowSend {
  &:before {
    content: $iconArrowSend;
  }
}

.iconPazzle {
  &:before {
    content: $iconPazzle;
  }
}

.iconUser {
  &:before {
    content: $iconUser;
  }
}

.iconUsers {
  &:before {
    content: $iconUsers;
  }
}

.iconCopy {
  &:before {
    content: $iconCopy;
  }
}

.iconLink {
  &:before {
    content: $iconLink;
  }
}

.iconEdit {
  &:before {
    content: $iconEdit;
  }
}

.iconDropdownArrow {
  &:before {
    content: $iconDropdownArrow;
  }
}

.iconDropdownArrow_after {
  &:after {
    content: $iconDropdownArrow;
  }
}

.iconDisconnect {
  &:before {
    content: $iconDisconnect;
  }
}

.iconLogout {
  &:before {
    content: $iconLogout;
  }
}

.iconLogout_after {
  &:after {
    content: $iconLogout;
  }
}

.iconSeparator {
  &:before {
    content: $iconSeparator;
  }
}

.iconFacebook {
  &:before {
    content: $iconFacebook;
  }
}

.iconLinkedin {
  &:before {
    content: $iconLinkedin;
  }
}

.iconDiscord {
  &:before {
    content: $iconDiscord;
  }
}

.iconTelegram {
  &:before {
    content: $iconTelegram;
  }
}

.iconDone {
  &:before {
    content: $iconDone;
  }
}

.iconRadioUnchecked {
  &:before {
    content: $iconRadioUnchecked;
  }
}

.iconRadioChecked {
  &:before {
    content: $iconRadioChecked;
  }
}
