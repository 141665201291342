@use '../../../styles/styles.scss' as *;

.selector {
  position: relative;
  z-index: 150;
  @include themed() {
    @include fontStyle(7px, 500, 8px);
    color: t($textPrimaryMain);
  }

  &__container {
    cursor: pointer;
    @include flexCenter;
    justify-content: flex-start;
    width: 100%;
    gap: 12px;

    &::after {
      rotate: 270deg;
    }

    &_open {
      &::after {
        rotate: 0deg;
      }
    }
  }

  &__text {
    @include fontRoboto;
    @include fontStyle(20px, 500, 24px);
    @include themed() {
      color: t($textPrimaryMain);
    }
  }

  &__img {
    cursor: pointer;
    @include width-height(32px);

    & img {
      @include width-height(100%);
      object-fit: cover;
    }
  }

  &__list {
    position: absolute;
    min-width: 250px;
    top: -75px;
    right: -10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 12px 12px 20px 20px;
    z-index: 50;
    padding: $space5;
    @include themed() {
      border: 1px solid t($surfaceBorderSubtitles);
      background: t($surfacePrimary2);
    }

    &::before {
      align-self: flex-end;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -14px;
      display: block;
      @include width-height(52px, 16px);
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.58547 1.84438C9.17557 1.23113 10.1571 1.23113 10.7472 1.84438L17.5942 8.95994C18.5111 9.91289 17.8358 11.5 16.5133 11.5H2.8194C1.4969 11.5 0.821546 9.91289 1.73853 8.95994L8.58547 1.84438Z' fill='%23222222' stroke='%23636363'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__item {
    background-color: transparent;
  }
}

.item {
  @include flex-grid(flex, none, none, center);
  align-self: stretch;
  @include width-height(100%, 58px);
  padding: $space4;
  gap: 12px;
  cursor: pointer;
  @include fontStyle(16px, 400, 20px);
  @include themed() {
    color: t($textPrimaryMain);
  }

  &:hover {
    @include themed() {
      background: t($gradientButtonHover1);
    }
    border-radius: $space4;
  }

  &__title {
    display: flex;
    gap: 12px;
    white-space: nowrap;

    &::after {
      @include flexCenter;
      font-size: 6px;
      rotate: 270deg;
    }
  }

  &__icon {
    @include flexCenter;
    @include width-height(24px);

    & img {
      @include width-height(100%);
    }

    &_flag {
      @include width-height(24px);
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .selector {
    &__text {
      @include fontStyle(16px, 400, 20px);

    }
  }
  .item {
    @include fontStyle(16px, 700, 20px);
    height: 48px;
  }
}

@media all and (max-width: $sizeWidth480) {
  .selector {
    &__list {
      right: 0;
      left: 0;

      &::before {
        align-self: flex-start;
        right: 0;
        @include width-height(75px, 16px);
      }
    }
  }
}
