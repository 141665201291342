@use '../../../../styles/styles.scss' as *;

.button {
  @include buttonStyle;
  @include themed() {
    background: t($textAccent);
    color: t($bg);
  }
  @include fontStyle(14px, 700, 24px);
  padding: 8px 24px;
  height: fit-content;
  transition: background 0.3s ease;

  &:hover {
    @include themed() {
      background: t($buttonBgHover3);
      color: t($buttonLabelHover);
    }
  }
}

.gradient {
  @include themed() {
    background: t($gradientQuestProgress);
    color: t($textPrimaryMain);
  }
}

.secondary {
  @include themed() {
    background: t($buttonBgInactive);
  }

  &:hover {
    @include themed() {
      background: t($buttonBgInactive);
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .button {
    padding: 8px 20px;
  }
}

@media all and (max-width: $sizeWidth360) {
  .button {
    padding: 8px 16px;
  }
}
