@use '../../styles/styles.scss' as *;

.auth {
  max-width: 1016px;
  // min-height: 568px;
  padding: $space11 $space16 $space21;

  &__header {
    @include flex-grid(flex, row, space-between, center);
    gap: $space6;
    margin-bottom: $space13;
  }

  &__header_row {
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
  }

  &__space {
    width: 40px;
  }

  &__title {
    width: 100%;
    @include flexCenter;
    @include textStyleTitle3;
    line-height: 48px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: $space14;
  }

  &__back {
    font-size: 28px;
    background-color: transparent;
    margin-right: 16px;
    @include themed() {
      color: t($buttonLabelDefault1);
    }
    transition: color 0.3s ease;

    &:hover {
      @include themed() {
        color: t($buttonLabelHover2);
      }
    }
  }

  a {
    @include themed() {
      color: t($textPrimaryMain);
    }
  }
}

.metamask {
  padding-bottom: 16px;
}

.socials {
  &__title {
    @include fontRussoOne;
    @include fontStyle(20px, 400, 24px);
    margin-bottom: $space6;
  }

  &__body {
    height: 68px;
    @include flex-grid(flex, row, space-between, center);
    gap: $space6;
  }
}

@media all and (max-width: $sizeWidth1024) {
  .auth {
    max-width: 750px;
    // min-height: 564px;
    padding: 44px 30px;

    &__btnClose {
      padding: 8px;
    }

    &__back {
      font-size: 22px;
      margin-right: 5px;
    }

    &__header {
      gap: 5px;
    }

    &__title {
      @include fontStyle(32px, 400, 40px);
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .auth {
    max-width: none;
    min-height: none;
    @include width-height(100%, 100vh);
    padding: 40px 30px;

    &__title {
      margin-top: 28px;
    }
  }
  .socials {
    &__body {
      display: flex;
      flex-direction: column;
      height: fit-content;
    }

    &__title {
      text-align: start;
    }
  }
}

@media all and (max-width: $sizeWidth480) {
  .auth {
    max-width: none;
    min-height: none;
    @include width-height(100%, 100vh);

    &__title {
      @include fontStyle(24px, 400, 28px);
      margin-top: 28px;
    }

    &__body {
      gap: 36px;
    }
  }
  .socials {
    &__title {
      @include fontStyle(18px, 400, 24px);
    }
  }
}
