@font-face {
  font-family: 'RussoOne';
  src: url('../../assets/fonts/RussoOne-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'Noto Sans Devanagari';
  src: url('../../assets/fonts/NotoSansDevanagari-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
