@use "../../../styles/styles.scss" as *;

.popup {
  @include flex-grid(flex, row, center, center);
  gap: 24px;
  border-radius: 20px !important;

  &__image {
    width: 354px;
    margin: 20px;
  }
}

.content {
  width: 540px;
  height: 100%;
  @include flex-grid(flex, column, center, flex-end);
  gap: 40px;

  &__title {
    @include fontRussoOne;
    @include fontStyle(28px, 400, 32px);
    width: 100%;
    text-align: center;
  }

  &__subtitle {
    @include fontRussoOne;
    @include fontStyle(20px, 400, 32px);
    width: 100%;
    text-align: center;
  }

  &__image {
    display: none;
  }

  &__button {
    border-radius: 12px;
    padding: 8px 20px;
    min-width: 132px;
    @include fontRoboto;
    @include fontStyle(16px, 700, 24px);
  }
}

.panel {
  width: 100%;
  @include flex-grid(flex, column, center, flex-start);
  gap: 12px;
  padding: 24px 28px;
  box-shadow: 2px 4px 49.2px 0px rgba(255, 165, 0, 0.35),
  1px 1px 2.5px 0px rgba(0, 0, 0, 0.5);

  &__box {
    @include flex-grid(flex, row, center, center);
    gap: 8px;
  }

  &__icon {
    width: 40px;
  }

  &__text {
    @include fontRussoOne;
    @include fontStyle(28px, 400, 32px);
    color: $colorOrange400;
  }

  &__stars {
    display: none;
  }
}

@media all and (max-width: $sizeWidth1024) {
  .popup {
    max-width: 700px;

    &__image {
      display: none;
    }
  }

  .content {
    padding: 0 50px;
    position: relative;

    &__button {
      position: absolute;
      right: -5px;
      bottom: 40px;
    }
  }

  .panel {
    margin-bottom: 60px;
  }
}

@media all and (max-width: $sizeWidth480) {
  .popup {
    @include flexColumnCenter;
    width: 100%;
  }

  .content {
    padding: 0;
    width: 100%;
    @include flexColumnCenter;
    gap: 24px;

    &__title {
      @include fontStyle(24px, 400, 28px);
    }

    &__subtitle {
      @include fontStyle(16px, 400, 24px);
    }

    &__button {
      position: inherit;
      width: 100%;
    }

    &__image {
      display: flex;
      margin-bottom: 40px;
    }
  }

  .panel {
    margin-bottom: 0;
    padding: 12px;
    @include flex-grid(flex, row, center, center);

    &__icon {
      position: absolute;
      left: -6%;
      width: 85px;
    }

    &__text {
      @include fontStyle(16px, 400, 24px);
      position: relative;
      right: -15%;
    }

    &__box {
      &__second {
        display: none;
      }
    }

    &__stars {
      display: block;
      position: relative;
      right: -30%;
    }
  }
}
