@use '../../../styles/styles.scss' as *;

.title {
  @include popupText;
  @include fontStyle(28px, 400, 32px);

  &.red {
    @include themed {
      color: t($buttonPlaceholderError);
    }
  }

  &.green {
    @include themed {
      color: t($textAccent);
    }
  }
}
