//colors
$colorWhite: #fdfaff;
$colorWhiteOp10: #fdfaff1a;
$colorWhiteOp28: #fdfaff47;
$colorBlack: #171717;
$bgGreen: #49ffb3;
$bgBlue: #00fcff;
$darkenedBackground: #171717f2;

$colorGreenPrimary100: #a7f67b;
$colorGreenPrimary200: #89cb64;
$colorGreenPrimary300: #6ca14e;
$colorGreenPrimary400: #507a39;
$colorGreenPrimary500: #365426;
$colorGreenPrimary600: #1e3213;
$colorGreenPrimary700: #091405;

$colorGreenSecondary100: #9aec6c;
$colorGreenSecondary200: #7dc157;
$colorGreenSecondary300: #619843;
$colorGreenSecondary400: #477030;
$colorGreenSecondary500: #2e4b1e;
$colorGreenSecondary600: #17290d;
$colorGreenSecondary700: #091404;

$colorGreenTertiary100: #9aec6c;
$colorGreenTertiary200: #7dc157;
$colorGreenTertiary300: #619843;
$colorGreenTertiary400: #477030;
$colorGreenTertiary500: #2e4b1e;
$colorGreenTertiary600: #17290d;
$colorGreenTertiary700: #091404;

$colorPurplePrimary100: #dbd5fc;
$colorPurplePrimary200: #b5a7fa;
$colorPurplePrimary300: #9278f7;
$colorPurplePrimary400: #7241f2;
$colorPurplePrimary500: #5018be;
$colorPurplePrimary600: #2d0a72;
$colorPurplePrimary700: #14033d;

$colorPurpleSecondary100: #f3effd;
$colorPurpleSecondary200: #ded0fb;
$colorPurpleSecondary300: #bfa0f6;
$colorPurpleSecondary400: #a36df1;
$colorPurpleSecondary500: #8a2de7;
$colorPurpleSecondary600: #5d1c9f;
$colorPurpleSecondary700: #340c5d;

$colorPurpleTertiary100: #fdfaff;
$colorPurpleTertiary200: #eac5ff;
$colorPurpleTertiary300: #da8cff;
$colorPurpleTertiary400: #ce3dff;
$colorPurpleTertiary500: #a300cd;
$colorPurpleTertiary600: #6e008b;
$colorPurpleTertiary700: #3d004f;

$colorRed100: #fee4e2;
$colorRed200: #fecdca;
$colorRed300: #fda29b;
$colorRed400: #f97066;
$colorRed500: #f04438;
$colorRed600: #d92d20;
$colorRed700: #b32318;

$colorOrange100: #feefc7;
$colorOrange200: #fedf89;
$colorOrange300: #fec84b;
$colorOrange400: #fdb022;
$colorOrange500: #f79009;
$colorOrange600: #ff6301;
$colorOrange700: #ef5a05;

$colorBlue100: #e0e1ff;
$colorBlue200: #b4b5ff;
$colorBlue300: #8788fe;
$colorBlue400: #5659fe;
$colorBlue500: #0417fa;
$colorBlue600: #010a9e;
$colorBlue700: #00024b;

$colorGray100: #f1f1f1;
$colorGray200: #d3d3d3;
$colorGray300: #acacac;
$colorGray400: #868686;
$colorGray500: #636363;
$colorGray600: #414141;
$colorGray650: #2b2b2b;
$colorGray700: #222222;

//gradient
$gradientPurple: linear-gradient(to bottom, #8a2de7, #5659fe);
$gradientPurpleInvert: linear-gradient(to bottom, #5659fe, #882fe8);
$gradientGreen: linear-gradient(to bottom, #aaf97e 0%, #9aec6c 100%);
$gradientShadow: linear-gradient(to right, #6b17c1 0%, #3b41c9 100%);
$gradientMaskot: linear-gradient(to bottom, #5659fe 0%, #8a2de7 100%);
$gradientGray: linear-gradient(93deg,
        #1c1c1c 0%,
        rgba(69, 69, 69, 0.42) 41.57%,
        rgba(195, 195, 195, 0) 104.66%);

//shadow
$shadowPurpleBackLight: 0 0 10px 0 $colorPurpleSecondary500;
$shadowPurplePicture: 18px 0 28.8px 0 #675df91a;

//spacing
$spaceNone: 0;
$space2: 4px;
$space3: 8px;
$space4: 12px;
$space5: 16px;
$space6: 20px;
$space7: 24px;
$space8: 28px;
$space9: 32px;
$space10: 36px;
$space11: 40px;
$space12: 44px;
$space13: 48px;
$space14: 52px;
$space15: 56px;
$space16: 60px;
$space17: 64px;
$space18: 68px;
$space19: 72px;
$space20: 76px;
$space21: 80px;
$space22: 84px;
$space23: 88px;
$space24: 92px;

//sizes
$sizeContainerLarge: 1920px;
$sizeContainer: 1440px;

$sizeWidth1280: 1280px;
$sizeWidth1024: 1024px;
$sizeWidth768: 768px;
$sizeWidth767: 767px;
$sizeWidth480: 480px;
$sizeWidth360: 360px;

$sizeHeight1400: 1400px;
$sizeHeight1200: 1200px;
$sizeHeight1000: 1000px;
$sizeHeight800: 800px;
$sizeHeight600: 600px;

//images
$logo: url('../../assets/images/logo.svg');
$logoShort: url('../../assets/images/shortLogo.svg');
$bombSticker: url('../../assets/images/stickers/bomb.png');
$lightningSticker: url('../../assets/images/stickers/lightning.png');

//*mixins
//general font mixins
@mixin fontRoboto() {
  font-family: Roboto, Arial, Helvetica, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@mixin fontRussoOne() {
  font-family: RussoOne, Arial, Helvetica, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@mixin fontNotoSansDevanagari() {
  font-family: 'Noto Sans Devanagari', Arial, Helvetica, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@mixin fontStyle($size: 16px, $weight: 400, $height: normal) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
}

//fonts
@mixin textStyleTitle1() {
  @include fontRussoOne;
  font-weight: 400;
  line-height: normal;
  font-size: 48px;
}

@mixin textStyleTitle2() {
  @include fontRussoOne;
  font-weight: 400;
  line-height: normal;
  font-size: 36px;
}

@mixin textStyleTitle3() {
  @include fontRussoOne;
  font-weight: 400;
  line-height: normal;
  font-size: 40px;
}

@mixin textStyleSubtitle() {
  @include fontRoboto;
  @include fontStyle($size: 52px, $weight: 700, $height: 64px);
}

@mixin textStyleBody1() {
  @include fontRoboto;
  @include fontStyle($size: 24px, $weight: 500, $height: 32px);
}

@mixin textStyleBody2() {
  @include fontRoboto;
  @include fontStyle($size: 20px, $weight: 500, $height: 32px);
}

@mixin textStyleSecondary1() {
  @include fontRoboto;
  @include fontStyle($size: 20px, $weight: 400, $height: 26px);
}

@mixin textStyleSecondary2() {
  @include fontRoboto;
  @include fontStyle($size: 16px, $weight: 500, $height: 26px);
}

@mixin textStyleButton1() {
  @include fontRoboto;
  @include fontStyle($size: 20px, $weight: 700, $height: 32px);
}

@mixin textStyleButton2() {
  @include fontRoboto;
  @include fontStyle($size: 24px, $weight: 700, $height: 32px);
}

@mixin textStyleButton3() {
  @include fontRoboto;
  @include fontStyle($size: 28px, $weight: 700, $height: 32px);
}

//others
@mixin width-height($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin borderStyle($border, $borderRadius) {
  border: $border;
  border-radius: $borderRadius;
}

@mixin borderRadius($borderRadius) {
  border-radius: $borderRadius !important;
  -webkit-border-radius: $borderRadius;
  -moz-border-radius: $borderRadius;
}

@mixin flex-grid($display, $direction, $justify, $align) {
  display: $display;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin textCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@mixin unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  transition: transform 0.1s ease, filter 0.1s ease;

}

@mixin buttonStyle {
  cursor: pointer;
  background-color: transparent;
  border-radius: 40px;
  padding: 8px 24px;
  white-space: nowrap;
  @include themed() {
    color: t($textPrimaryMain);
  }
  @include fontStyle(16px, 700, 24px);
  @include fontRoboto;

  &:hover {
    @include themed() {
      background: t($buttonBgPressed);
      color: t($buttonLabelPressed);
    }
  }
}

@mixin popupText {
  @include fontRussoOne;
  @include themed {
    color: t($textPrimaryMain);
  }
}
