@use '../../../styles/styles.scss' as *;

.link {
  @include themed() {
    color: t($textAccent);
  }
  @include flexCenter;
  gap: 4px;
  @include fontStyle(16px, 700, 24px);

  &:hover {
    cursor: pointer;
    @include themed() {
      color: t($buttonBgHover3);
    }
  }
}

