@use '../../../../styles/styles.scss' as *;

.text {
  position: relative;
  display: inline-block;
  @include themed() {
    color: t($textPrimaryMain);
  }

  &_accent {
    @include themed() {
      color: t($textAccent);
    }
  }

  &__withBorder {
    position: relative;
    z-index: 0;
    @include themed() {
      -webkit-text-stroke: 0.2em t($surfaceBorder2);
      text-stroke: 0.2em t($surfaceBorder2);
    }
  }

  &__withoutBorder {
    position: absolute;
    z-index: 1;
  }

  &_space {
    display: inline-block;
    width: 0.25em;
  }
}
