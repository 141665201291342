@use '../../styles/styles.scss' as *;

.container {
  @include flexCenter;
  gap: 3px;

  &__points {
    @include themed() {
      color: t($buttonBgTertiary);
    }
    @include fontRussoOne;
    @include fontStyle(24px, 400, 32px);
  }

  &__img {
    @include width-height(26px)
  }
}

@media all and (max-width: $sizeWidth767) {
  .container {
    &__img {
      @include width-height(32px)
    }

    &__points {
      @include fontStyle(28px, 400, 32px);
    }
  }
}

