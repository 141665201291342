@use 'sass:map';
@import './variables.scss';
@import './themeVariables.scss';

$themes: (
        dark: (
          //space
                radiusSmall: $space5,
                radiusMedium: $space6,
                radiusLarge: $space9,
                radiusXl: $space11,
                spacingNone: $spaceNone,
          //colors
                formBackground: $colorGray650,
                formLabel: $colorGray100,
                formLabelPlaceholder: $colorGray400,
                inputBgGuides: $colorGray600,
                messageBgGuides: $colorWhiteOp28,
                trackBgGuides: $colorWhite,
                messageLabelGuides: $colorGray500,
                buttonBgUpProgressBar: $colorPurplePrimary300,
                buttonBgUpProgressBar2: $colorGreenTertiary100,
                buttonBgMidProgressBar: $colorGreenPrimary100,
                buttonBgMidProgressBar2: $colorPurplePrimary400,
                buttonBgDownProgressBar: $colorPurplePrimary500,
                buttonBgDownProgressBar2: $colorGreenTertiary200,
                bg: $colorBlack,
                surfacePrimary: $colorWhiteOp10,
                surfacePrimary2: $colorGray700,
                surfacePrimaryPopUp: $colorBlack,
                surfacePrimaryAccent: $colorGreenPrimary100,
                surfaceBorderAccent: $colorGreenPrimary100,
                surfaceBorderSubtitles: $colorGray500,
                surfaceBorder1: $colorGray600,
                surfaceBorder2: $colorBlack,
                textPrimaryMain: $colorWhite,
                textAccent: $colorGreenPrimary100,
                buttonBgActive: $colorGreenPrimary100,
                buttonBgActive2: $colorWhite,
                buttonBgActive3: $colorRed500,
                buttonBgActive4: $colorOrange600,
                buttonLabelActive: $colorBlack,
                buttonLabelActive2: $colorGreenPrimary100,
                buttonLabelActive3: $colorGray400,
                buttonLabelActive4: $colorWhite,
                buttonBgHover: $colorGreenPrimary100,
                buttonLabelHover: $colorBlack,
                buttonLabelHover1: $colorWhite,
                buttonLabelHover2: $colorGray650,
                buttonBorderHover: $colorGray200,
                buttonBgHover3: $colorGreenPrimary200,
                buttonBgHover4: $colorOrange700,
                buttonBgPressed: $colorGreenPrimary100,
                buttonLabelPressed: $colorBlack,
                buttonLabelDisabled: $colorGray500,
                buttonBackgorundDisabled: $colorGray500,
                buttonBgDefault: $colorGray650,
                buttonBgDefault1: $colorGray400,
                buttonLabelDefault: $colorWhite,
                buttonLabelDefault1: $colorGray400,
                buttonBgInactive: $colorGray400,
                buttonLabelInactive: $colorBlack,
                buttonBgProgress: $colorGreenPrimary100,
                buttonLabelProgress: $colorGreenPrimary100,
                buttonLabelProgress1: $colorBlack,
                buttonBgError: $colorRed500,
                buttonPlaceholderError: $colorRed500,
                buttonBorderSuccess: $colorGreenPrimary100,
                buttonLabelSuccess: $colorGreenPrimary100,
                bgSecondary: $colorWhite,
                buttonBackgroundSecondary: $colorGreenPrimary100,
                buttonLabelSecondary: $colorGreenPrimary100,
                buttonBgTertiary: $colorOrange400,
                buttonLabelTertiary: $colorOrange400,
          //gradient
                gradientImage: $gradientPurple,
                gradientButtonHover: $gradientPurple,
                gradientLabelHover: $gradientPurple,
                gradientBorderLogoDark: $gradientPurpleInvert,
                gradientBgFormDark: $gradientGreen,
                gradientButtonHover1: $gradientGray,
                gradientQuestProgress: $gradientPurple,
          //shadow
                shadowBgPopup: $shadowPurpleBackLight,
          //images
                imageLogo: $logo,
                imageLogoShort: $logoShort,
                imageHelloLeftTopSticker: $lightningSticker,
                imageHelloRightDownSticker: $bombSticker,
        ),
        light: (),
);

@mixin themed {
  @each $theme, $map in $themes {
    :global(.theme--#{$theme}) & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map.get($map, $key);
        $theme-map: map.merge(
                        $theme-map,
                        (
                                $key: $value,
                        )
        ) !global;
      }
      @content;

      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
