@use '../../styles/styles.scss' as *;

.content {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: url('../../assets/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  @include flexCenter;
  @include themed() {
    background-color: t($bg);
  }

  &__container {
    display: flex;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  &__title {
    @include fontRussoOne;
    @include fontStyle(48px, 400, 56px);
    text-align: center;
    @include themed() {
      color: t($textPrimaryMain);
    }
  }

  &__subtitle {
    @include fontRussoOne;
    @include fontStyle(36px, 400, 44px);
    text-align: center;
    @include themed() {
      color: t($textPrimaryMain);
    }
  }

  &__button {
    @include fontStyle(20px, 700, 24px);
    margin-top: 20px;
    padding: 16px 32px;
    width: fit-content;
  }
}


@media all and (max-width: $sizeWidth1280) {
  .content {
    padding: 20px;

    &__container {
      display: flex;
    }

    &__title {
      @include fontStyle(36px, 400, 44px);
    }

    &__subtitle {
      @include fontStyle(28px, 400, 32px);
    }

    &__button {
      @include fontStyle(16px, 700, 24px);
      padding: 12px 24px;
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .content {
    &__title {
      @include fontStyle(24px, 400, 28px);
    }

    &__subtitle {
      @include fontStyle(16px, 400, 24px);
    }

    &__button {
      @include fontStyle(16px, 700, 24px);
      width: 100%;
    }
  }
}
