@use '../../../styles/styles.scss' as *;

.audioRate {
  @include flexCenter;
  @include width-height(52px, 28px);
  @include fontStyle(16px, 500, 20px);
  @include themed() {
    border: 2px solid t($buttonBgActive2);
    border-radius: t($radiusMedium);
    color: t($buttonBgActive2);
  }
  background-color: transparent;

  &:hover {
    transition: 0.3s;
    @include themed() {
      border-color: t($buttonBorderHover);
      color: t($buttonBorderHover);
    }
  }
}

@media screen and (max-width: $sizeWidth768) {
  .audioRate {
    @include fontStyle(14px, 500);
  }
}
