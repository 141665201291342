@use '../../../../styles/styles.scss' as *;

.button {
  cursor: pointer;

  &_icon {
    @extend .button;
    background-color: transparent;
  }

  &_accent,
  &_border {
    @include flexCenter;
    white-space: nowrap;
    transition: 0.3s;
    border-radius: 40px;
    gap: 8px;

    &:disabled {
      @include themed() {
        border-color: t($buttonBackgorundDisabled);
        color: t($buttonLabelDisabled);
        background-color: transparent;
      }

      &:hover {
        @include themed() {
          border-color: t($buttonBackgorundDisabled);
          color: t($buttonLabelDisabled);
          background-color: transparent;
        }
      }
    }
  }

  &_accent {
    @include themed() {
      background: t($buttonBgActive);
      color: t($buttonLabelActive);
    }

    &::before {
      font-size: $space8;
      animation: spin 2s linear 0s infinite;
    }

    &:hover {
      @include themed() {
        background: t($buttonBgHover3);
        color: t($buttonLabelHover);
      }
    }
  }

  &_border {
    background-color: transparent;
    gap: 4px;
    @include themed() {
      @include borderStyle(3px solid t($buttonBgActive), 40px);
      color: t($buttonLabelActive2);
    }

    &:hover {
      @include themed() {
        background-color: t($buttonBgHover);
        color: t($buttonLabelHover);
      }
    }

    &::before {
      font-size: $space8;
      animation: spin 2s linear 0s infinite;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
