@use '../../../../styles/styles.scss' as *;

.loader {
  position: relative;
  @include flexCenter;
  @include width-height(fit-content);

  &__mascot {
    @include width-height(fit-content);
    @include flexCenter;

    & img {
      @include width-height(100%);
    }
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  &__img {
    position: absolute;
    min-height: 20px;
    min-width: 20px;
    left: 50%;
    // top: 0;
    margin-top: -15%;
    transform-origin: 0 300px;
    animation: img1 1.5s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  }

  &__img:nth-child(1) {
    animation-name: img1;
    animation-delay: 150ms;
    transform: rotateZ(-90deg);
    margin-left: 30px;
  }

  &__img:nth-child(2) {
    animation-name: img2;
    animation-delay: 200ms;
    transform: rotateZ(-50deg);
    margin-left: 30px;
  }

  &__img:nth-child(3) {
    animation-name: img3;
    animation-delay: 250ms;
    transform: rotateZ(-30deg);
  }

  &__img:nth-child(4) {
    animation-name: img4;
    animation-delay: 300ms;
    transform: rotateZ(40deg);
  }

  &__img:nth-child(5) {
    animation-name: img5;
    animation-delay: 450ms;
    transform: rotateZ(80deg);
    margin-left: -30px;
  }
}

.typeMetamask .spinner__img:nth-child(4) {
  background-color: $colorWhite;
  border-radius: 8px;
  padding: 5px;
}

@keyframes img1 {
  0% {
    transform: rotateZ(-90deg) rotateX(0deg);
    margin-left: 30px;
  }

  50% {
    margin-left: -30px;
  }

  95%,
  100% {
    transform: rotateZ(270deg) rotateX(0deg);
    margin-left: 30px;
  }
}

@keyframes img2 {
  0% {
    transform: rotateZ(-50deg) rotateX(0deg);
    margin-left: 30px;
  }

  50% {
    margin-left: -30px;
  }

  95%,
  100% {
    transform: rotateZ(310deg) rotateX(0deg);
    margin-left: 30px;
  }
}

@keyframes img3 {
  0% {
    transform: rotateZ(-30deg) rotateX(0deg);
  }

  95%,
  100% {
    transform: rotateZ(330deg) rotateX(0deg);
  }
}

@keyframes img4 {
  0% {
    transform: rotateZ(40deg) rotateX(0deg);
  }

  95%,
  100% {
    transform: rotateZ(400deg) rotateX(0deg);
  }
}

@keyframes img5 {
  0% {
    transform: rotateZ(80deg) rotateX(0deg);
    margin-left: -30px;
  }

  50% {
    margin-left: 30px;
  }

  95%,
  100% {
    transform: rotateZ(440deg) rotateX(0deg);
    margin-left: -30px;
  }
}
