@use '../../../../styles/styles.scss' as *;

.button {
  @include buttonStyle;
  @include themed() {
    border: 2px solid t($textAccent);
    color: t($textAccent);
  }
}

@media all and (max-width: $sizeWidth480) {
  .button {
    padding: 8px 20px;
  }
}