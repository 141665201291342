@use '../../../styles/styles.scss' as *;

.modal {
  &Transaction {
    width: 1016px;
    height: 452px;
    padding: 36px 40px;
    overflow-y: hidden;

    &__container {
      display: flex;
      @include width-height(100%);
      gap: 40px;
    }

    &__imgBlock {
      position: absolute;
      display: flex;
      align-self: flex-start;
      align-items: flex-end;
      width: fit-content;
      @include width-height(372px, 416px);
      min-width: 372px;

      &_img {
        position: absolute;
        top: 0;
        width: 372px;
        object-fit: cover;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
    }

    &__title {
      width: 528px;
      @include textCenter;
      @include fontStyle(36px, 400, 44px);
      @include fontRussoOne;
      margin-top: 36px;
      @include themed() {
        color: t($textPrimaryMain);
      }
    }

    &__text {
      width: 528px;
      text-align: center;
      @include fontStyle(24px, 700, 32px);
      flex: 0.5 1 auto;
    }

    &__btn {
      @include fontStyle(20px, 700, 24px);
      min-width: 160px;
      height: $space14;
      padding: 0 32px;
      align-self: flex-end;
    }
  }
}

@media all and (max-width: $sizeWidth1280) {
  .modal {
    &Transaction {
      max-width: 700px;
      padding: 36px 40px 20px 20px;

      &__imgBlock {
        @include width-height(372px, 416px);
        min-width: 240px;
        margin-top: 80px;

        &_img {
          width: 240px;
        }
      }

      &__title, &__text {
        width: 379px;
      }
    }
  }
}

@media all and (max-width: $sizeWidth768) {
  .modal {
    &Transaction {
      max-width: none;
      @include width-height(100%, 100vh);

      &__imgBlock {
        @include flexCenter;
        height: fit-content;
        width: 100%;
        min-width: 240px;
        flex: 0.5 1 auto;
        position: relative;
        margin: 0;

        &_img {
          top: 0;
          position: relative;
        }
      }

      &__info {
        gap: $space8;
        align-items: center;
      }

      &__title {
        width: 100%;
        @include fontStyle(32px, 400, 40px);
      }

      &__text {
        width: 100%;
        flex: 0 1 auto;
      }

      &__btn {
        align-self: stretch;
        min-height: $space14;
      }
    }
  }
}
