@use '../../../styles/styles.scss' as *;

.text {
  @include popupText;
  @include fontStyle(24px, 400, 28px);
}

@media all and (max-width: $sizeWidth767) {
  .text {
    @include fontStyle(16px, 400, 24px);
  }
}